import firebase from "firebase/app";
import "firebase/firestore";
import { Episode } from '../interfaces/Episode'
import { Podcast } from '../interfaces/Podcast'
import { EpisodeCard } from '../interfaces/EpisodeCard';

export interface SearchResult {
    tag: string
    name: string
    count: number
    image: string
    clicked?: boolean
}

export interface PodcastMetadata {
    image300: string,
    name: string
}

export async function fetchSinglePodcastList(podcastId: string) {
    let cards: EpisodeCard[] = []

        let podcast = await fetchEpisodesFromPodcast(podcastId)
        podcast.forEach(pod => {
            let card: EpisodeCard = { episode: pod, name: podcastId }
            cards.push(card)
        })
    
    return cards

}

export async function fetchEpisodesCountFromPodcast(podcastName: string) {
    const podcastRef = firebase.firestore().collection('podcasts').doc(podcastName).collection('episodes');
    const snapshot = await podcastRef.get()
    return snapshot.size
}

export async function fetchEpisodesFromPodcast(podcastName: string) {
    let episodes: Episode[] = []
    const podcastRef = firebase.firestore().collection('podcasts').doc(podcastName).collection('episodes');
    const snapshot = await podcastRef.get()
    snapshot.forEach(doc => {
        let data: Episode = <Episode>doc.data()
        data.key = doc.id
        episodes.push(data)
    })
    return episodes
}

export async function createNewPodcast(podTag: string, name: string) {
    try {
    await firebase.firestore().collection('podcasts').doc(podTag).set({
        name: name
    })
    return true
    } catch(e) {
    return false
    }
}

export async function fetchPodcastMetadata(podcastName: string) {
    const podcastRef = firebase.firestore().collection('podcasts').doc(podcastName)
    const snapshot = await podcastRef.get()
    return snapshot.data() as PodcastMetadata
}

export async function setPlayableToFirestore(episode: Episode) {
    try {
    let user = firebase.auth().currentUser
    let uid = user!.uid
    console.log(uid)
    let { duration, name, key, image300} = episode
    let created = firebase.firestore.FieldValue.serverTimestamp()

    await firebase.firestore().collection('users').doc(uid).collection('playable').doc('current').set({
        created, duration, name, key, image300
    })
    return true
    } catch(e) {
    console.log(e)
    return false
    }
}

export async function getPlayableFromFirestore() {
    let user = firebase.auth().currentUser
    let uid = user!.uid
    const playableRef = firebase.firestore().collection('users').doc(uid).collection('playable').doc('current')
    const snapshot = await playableRef.get()
    if (snapshot.exists) {
        let episode = snapshot.data() as Episode
        return episode
    } else {
        return null
    }
}

export async function clearPlayableToFirestore() {
    try {
        let user = firebase.auth().currentUser
        let uid = user!.uid    
        await firebase.firestore().collection('users').doc(uid).collection('playable').doc('current').delete()
        return true
        } catch(e) {
        console.log(e)
        return false
        }

}
import { DateTime, Duration } from "luxon"
import firebase from 'firebase'
export function firestoreTimestampToLuxon(timestamp: firebase.firestore.Timestamp) {
    return DateTime.fromMillis(timestamp.toMillis())
}

export function durationSecondsToLuxon(duration: number, format: string) {
    return Duration.fromMillis(duration * 1000).toFormat(format)
}

export function millsToTimeString(duration: number) {
    let offset = 7200 * 1000 //todo fix
    let dt = DateTime.fromMillis(duration - offset)
    //console.log('pituus on ' + duration)
    return dt.toFormat("HH:mm:ss")
}



function isCharNumber(char:string) {
    let parsedInt = parseInt(char)
    if (isNaN(parsedInt)) {
        return false
    } else {
        return true
    }
}

function isCharSemiColon(input:string) {
    if (input === ':') {
        return true
    } else {
        return false
    }
}

export function validateTimeInput(input:string) {

if (input.length !== 8) {
    return false
}

let c0 = input.charAt(0)
let c1 = input.charAt(1)
let c2 = input.charAt(2)
let c3 = input.charAt(3)
let c4 = input.charAt(4)
let c5 = input.charAt(5)
let c6 = input.charAt(6)
let c7 = input.charAt(7)

if (!(isCharNumber(c0))) {
    return false
}

if (!(isCharNumber(c1))) {
    return false
}

if (!(isCharSemiColon(c2))) {
    return false
}

if (!(isCharNumber(c3))) {
    return false
}

if (!(isCharNumber(c4))) {
    return false
}

if (!(isCharSemiColon(c5))) {
    return false
}

if (!(isCharNumber(c6))) {
    return false
}

if (!(isCharNumber(c7))) {
    return false
}

return true

}

export function getMillsFromInput(input:string) {
    let inputArray = input.split(':')
    let hours = parseInt(inputArray[0])
    let minutes = parseInt(inputArray[1])
    let seconds = parseInt(inputArray[2])
    
    let hMills = hours * 3600000
    let mMills = minutes * 60000
    let sMills = seconds * 1000
    
    return hMills + mMills + sMills
    }
    
import React from 'react'
import styled from 'styled-components'
import * as colors from './colors'
let statusbarH = 0

interface BaseViewProps {
    readonly justifyContentCenter?: boolean;
    readonly justifyContentSpaceBetween?: boolean;
    readonly alignItemsCenter?: boolean;
    readonly whiteBackground?: boolean;
    readonly noTopPadding?: boolean;
}

interface InputFieldProps {
    readonly alignLeft?: boolean;
    readonly flex1?: boolean;
    readonly flex2?: boolean;
    readonly autoWidth?: boolean;
    readonly fullWidth?: boolean;
    readonly active?: boolean;
    readonly error?: boolean;
    readonly bottomMarginToNext?: number;
}

interface RowProps {
    readonly alignItemsCenter?: boolean;
}

interface PlayerBoxProps {
    readonly active?: boolean;
    readonly error?: boolean;
    readonly justifyCenter?: boolean;
}

export const View = styled.div`
display:flex;
`
  
export const BaseView = styled(View)<BaseViewProps>`
flex:1;
padding-top: ${`${statusbarH}px`};
flex-direction:column;
flex-grow: 1;
width:100vw;
position: absolute;
top: 0;
bottom: 0;
left: 0;

${props => props.justifyContentCenter && `justify-content:center`}
${props => props.justifyContentSpaceBetween && `justify-content: space-between`}
${props => props.alignItemsCenter && `align-items:center`}
${props => props.whiteBackground ? `background-color: ${colors.white}` : `background-color: ${colors.grey1}`}
${props => props.noTopPadding && `padding-top: 0px`}
`

/*
export const BaseScrollView = styled.ScrollView`
flex:1;
padding-top: ${`${statusbarH}px`};

${props => props.justifyContentCenter && `justify-content:center`}
${props => props.justifyContentSpaceBetween && `justify-content: space-between`}
${props => props.alignItemsCenter && `align-items:center`}
${props => props.whiteBackground ? `background-color: ${colors.white}` : `background-color: ${colors.grey1}`}
${props => props.noTopPadding && `padding-top: 0px`}
`*/



export const OnboardingView = styled(View)`
background-color: ${colors.grey1};
flex-direction:column;
justify-content:center;
align-items:center;
flex:1;
`

export const OnboardingBox = styled(View)`
width: 90%;
background-color: ${colors.white};
border-radius: 5px;
border-width: 1px;
border-color: ${colors.grey2};
padding-left: 15px;
padding-right: 15px;
padding-top: 20px;
padding-bottom: 20px;
`

export const ListItem = styled(View)`
flex: 1;
border-radius: 5px;
padding: 5px;
margin-top: 10px;
margin-bottom: 10px;
background-color: ${colors.white};
`

export const ListItemTouchable = styled(View)`
margin: 10px 15px;
flex-direction:row;
border-radius: 5px;
padding: 5px;
background-color: ${colors.white};
`

export const InputField = styled.input<InputFieldProps>`
border-radius: 21px;
border-width:1px;
background-color:${colors.grey1};
height:42px;
width: 80%;
${props => !props.alignLeft && 'text-align: center'}
${props => props.alignLeft && 'padding-left:15px'}
${props => props.bottomMarginToNext && `margin-bottom:15px`}
${props => props.active ? `border-color: ${colors.yellow}` : `border-color: ${colors.grey2}`}
${props => props.error ? `border-color: ${colors.red}` : `border-color: ${colors.grey2}`}
${props => props.fullWidth ? 'width: 100%' : 'width:80%'}
${props => props.autoWidth ? 'width: auto' : 'width: 80%'}
${props => props.flex2 && 'flex: 2'}
${props => props.flex1 && 'flex: 1'}

`

export const Row = styled(View)<RowProps>`
display:flex;
flex-direction:row;
align-items:center;
${props => props.alignItemsCenter && `align-items:center`}
${props => props.alignItemsCenter && `background-color:green;`}
`

export const PlayerBox = styled(View)<PlayerBoxProps>`
border-radius:5px;
border-width:1px;
height: 60px;
margin-bottom:15px;
padding: 5px;
margin-left:10px;
margin-right:10px;
background-color: ${colors.white};
justify-content:space-between;
align-items:center;
flex-direction:row;
border-color: ${colors.grey2};
${props => props.active &&  `border-color: ${colors.yellow}`}
${props => props.error && `border-color: ${colors.red}`}
${props => props.justifyCenter && `justify-content: center`}
`
import React from 'react'
import styled from 'styled-components'

import * as colors from './colors'

interface BaseTextProps {
    readonly white?: boolean;
    readonly inactive?: boolean;
    readonly hint?: boolean;
    readonly alignCenter?: boolean;
    readonly marginB?: number;
    readonly marginT?: number;
    readonly marginL?: number;
}

export const BaseText = styled.label<BaseTextProps>`
color: ${colors.black}
${props => props.marginB && `margin-bottom:${props.marginB}px`}
${props => props.marginT && `margin-top:${props.marginT}px`}
${props => props.marginL && `margin-left:${props.marginL}px`}
${props => props.alignCenter && `text-align: center`}
${props => props.hint && `color: ${colors.grey2}`}
${props => props.inactive && `color: ${colors.grey3}`}
${props => props.white && `color: ${colors.white}`}
`

export const Header1 = styled(BaseText)`
font-size: 18px;
font-family: NunitoSans-ExtraBold;
`

export const X = styled(BaseText)`
font-size: 24px;
font-weight: 900;
`


export const Header2 = styled(BaseText)`
font-size: 14px;
font-family: NunitoSans-ExtraBold;
`

export const Header3 = styled(BaseText)`
font-size: 14px;
`

export const Paragraph = styled(BaseText)`
font-size: 14px;
`

export const Inactive = styled(BaseText)`
font-family: NunitoSans-Regular;
font-size: 12px;
`

export const ButtonText = styled(BaseText)`
font-weight: 900;
font-size: 14px;
`
import React from 'react';
import styled from 'styled-components'

import splash from '../../images/splash.png'
import appBarLogo from '../../images/appBarLogo.svg'


const LoadingView = styled.div`
margin:0;
padding:0;
background-color:white;
width:100vw;
height:100vh;
display:flex;
justify-content:center;
`

export function Loading() {
return(
<LoadingView>
    <img src={appBarLogo} style={{width: '30%'}}/>
</LoadingView>
)
}
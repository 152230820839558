import React, { useEffect, useState } from 'react';
import firebase from "firebase/app";
import styled from 'styled-components'
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import logo from './logo.svg';
import spotify from './images/spotify.png'
import * as colors from './styles/colors'


import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
  Link
} from "react-router-dom"
import { Podcast } from './scenes/Podcast/Podcast'
import { Loading } from './scenes/Loading/Loading'
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyBRpIMvCBVfSlcDqwvcsZn8sXf090Rl460",
    authDomain: "podtunes-ca5a8.firebaseapp.com",
    databaseURL: "https://podtunes-ca5a8.firebaseio.com",
    projectId: "podtunes-ca5a8",
    storageBucket: "podtunes-ca5a8.appspot.com",
    messagingSenderId: "801988255845",
    appId: "1:801988255845:web:e220eb38d2967bdca5268c",
    measurementId: "G-81Y33QL6X0"
  }
export var fireApp = firebase.initializeApp(firebaseConfig)
export var analytics = firebase.analytics()

const Modal = styled.div`
position: absolute;
float: left;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
background-color: white;
padding: 30px 20px;
width: 90%;
display:flex;
flex-direction:column;
align-items:center;
border-radius:5px;
`

const InfoText = styled.p`
text-align:center;
`

const Button = styled.div`
width: 167px;
height: 44px;
border-radius: 22px;
margin-bottom: 10px;
border-color: ${colors.grey2};
background-color: ${colors.black};
display:flex;
align-items:center;
justify-content:center;
cursor: pointer;
`
const ButtonText = styled.label`
font-weight: black;
color: white;
`

const SkipLink = styled.label`
text-decoration: underline;
color: ${colors.grey3};
cursor: pointer;
`


export default function App() {

  const [firebaseLoginLoading, setFirebaseLoginLoading] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {

    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        firebase.auth().signInAnonymously().then(res => {
          if (window.localStorage.getItem('firstRun') === null) {
            window.localStorage.setItem('firstRun', 'false')
            setModalVisible(true)
          }
          setFirebaseLoginLoading(false)
        })
      } else {
          setFirebaseLoginLoading(false)
      }
  })

  }, [])


  return (
    <Router>
        <Switch>
          <Route path="/podcast/:podcastId">
            {firebaseLoginLoading ? <Loading /> : <Podcast putModalVisible={() => setModalVisible(true)}/>}
          </Route>
          <Route path="/spotify-login">
            <SpotifyLogin />
          </Route>
          <Route path="/spotify-callback">
            <SpotifyCallback />
          </Route>
        </Switch>
        {modalVisible && (

        
        <div  style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: "rgba(0, 0, 0, 0.35)"
      }}>
<Modal>
<img src={spotify} style={{width: 80, height: 80}}></img>

  <InfoText>Podtuna needs to connect to your Spotify 
account. Don't worry - we don't collect any 
information, it's only for controlling playback of 
podcast episodes and the songs added to them. 
You can disconnect whenever you want.</InfoText>
<InfoText>The button below will take you to Spotify's 
permission page</InfoText>
<Link to="/spotify-login" onClick={() => setModalVisible(false)}>
<Button>
  <ButtonText>Connect</ButtonText>
</Button>
</Link>

<SkipLink onClick={() => setModalVisible(false)}>{'Skip for now >'}</SkipLink>
</Modal>

        </div>
)}
    </Router>
  )
}

function SpotifyLogin() {

  useEffect(() => {
    firebase.functions().httpsCallable('getChallengePwa')().then(response => {
      let uri = response.data
      console.log(uri)
      window.location.href = uri  
    })
  }, [])
  
return <Loading />
}

function SpotifyCallback() {
  interface CallbackUrlTypes {
    code: string
  }
  let location = useLocation()
  const urlParams = new URLSearchParams(location.search)

  //      functions().httpsCallable('postToken')({code}).then(response => {

  useEffect(() => {
    const code = urlParams.get('code')
    if (code !== null) {
      console.log(code)
      firebase.functions().httpsCallable('postTokenPwa')({code}).then(response => { 
      console.log('posti ok!')
      let redirectTo = window.localStorage.getItem('lastUrl')
      if (redirectTo !== null) {
        window.location.href = redirectTo
      }
      })
  
    }
  }, [])

  return <Loading />
}

import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"
import { fetchSinglePodcastList, setPlayableToFirestore, getPlayableFromFirestore, clearPlayableToFirestore, fetchPodcastMetadata, PodcastMetadata } from '../../helpers/firestore';
import { firestoreTimestampToLuxon, durationSecondsToLuxon, millsToTimeString } from '../../helpers/common'
import firebase from 'firebase'
import { EpisodeCard } from '../../interfaces/EpisodeCard';
import { Episode } from '../../interfaces/Episode'
import * as c from '../../styles/common'
import * as texts from '../../styles/textstyles'
import styled from 'styled-components'
import durationImg from '../../images/duration.png'
import play from '../../images/play.png'
import stop from '../../images/stop.png'
import error from '../../images/error.png'
import close from '../../images/close.png'
import { Loading } from '../Loading/Loading'
import { DateTime, Duration } from "luxon"


const TrackDetails = styled(c.View)`
flex-direction:column;
padding-left:12px;
flex-shrink: 1;
justify-content:space-between;

`

const TrackDetailsRow = styled(c.View)`
flex:1;
flex-direction:row;
`

const PlayButton = styled(c.View)`
justify-content:center;
align-items:center;
padding:20px;
`


  

interface PodcastUrlParameters {
    podcastId: string
}

export function Podcast(props:any) {
const [list, setList] = useState<EpisodeCard[]>([])
const params = useParams<PodcastUrlParameters>()
const [isActive, setIsActive] = useState(true)
const [playable, setPlayable] = useState<Episode>()
const [metadata, setMetadata] = useState<PodcastMetadata>()
const [playerStatus, setPlayerStatus] = useState('not-active') //not-active, started, no-active-client
const history = useHistory()
const startPlayback = (podcastId:string, episodeId: string, episode: Episode) => {
    firebase.analytics().logEvent('playback_start', { name: episode.name, podcast: podcastId})
    setPlayerStatus('loading')
    firebase.functions().httpsCallable('control')({command: 'start', podcastId, episodeId}).then(response => {
        let status = response.data.status
        if (status === 'token-update-needed') {
            props.putModalVisible()
        }
        console.log(status)
        setPlayable(episode)
        setPlayableToFirestore(episode)
        setPlayerStatus(status)
      })
}   
const stopPlayback = () => {

    firebase.functions().httpsCallable('control')({command: 'stop'}).then(response => {
        let status = response.data.status
        if (status === 'token-update-needed') {
          //  navigation.navigate('MyModal')
        }
        console.log(status)
        setPlayerStatus('not-active')
        clearPlayableToFirestore()
      })
}

const renderItem = (item: EpisodeCard) => {

    let luxo = firestoreTimestampToLuxon(item.episode.created)
    let timeString = `${luxo.day}.${luxo.month}.${luxo.year}`
    let duration = durationSecondsToLuxon(item.episode.duration, "hh:mm:ss")
    let image = 'https://via.placeholder.com/90'
    if (item.episode.hasOwnProperty('image300')) {
        image = item.episode.image300
    }
    return (
    <c.ListItemTouchable key={item.episode.key}>

        <TrackDetailsRow>
        <img src={image} style={{width: 90, height: 90, borderRadius:3 }}></img>
        <TrackDetails>
    <c.View style={{flexDirection: 'column'}}>
    <texts.Header2>{item.episode.name}</texts.Header2>
    <texts.Inactive>{`#${item.name}`}</texts.Inactive>
    </c.View>
    <c.Row>
                        <img src={durationImg} style={{marginLeft: -5}} />
                        <texts.Inactive>{millsToTimeString(item.episode.duration)}</texts.Inactive>
                    </c.Row>
    </TrackDetails>
    </TrackDetailsRow>
    <PlayButton onClick={() => startPlayback(item.name, item.episode.key, item.episode)}>
        <img src={play} />
    </PlayButton>
    </c.ListItemTouchable>
    )
}

    useEffect(() => {

        async function fetchData() {
            const episodes = await fetchSinglePodcastList(params.podcastId)
            const episode = await getPlayableFromFirestore()
            const metadata = await fetchPodcastMetadata(params.podcastId)
            if (episode !== null) {
                let episodeStarted = firestoreTimestampToLuxon(episode.created)
                let currentTime = DateTime.local()
                const diff = currentTime.diff(episodeStarted, ["minutes"])
                const minutes = diff.minutes
                if (minutes < 120) {
                    setPlayable(episode)
                    setPlayerStatus('started')
                }
            }
            setMetadata(metadata)
            setList(episodes)
            setIsActive(false)
            window.localStorage.setItem('lastUrl', window.location.href)
            firebase.analytics().logEvent('podcast_page_load', { name: params.podcastId})
        }
        fetchData()
    }, [])
    
    if (isActive) {
        return <Loading />
    }
   
    return <c.BaseView>
{metadata !== undefined && (
<c.View style={{flexDirection: 'row', backgroundColor: 'white', paddingLeft:15, paddingBottom: 10, paddingRight: 15, paddingTop: 15}}>
        <img src={metadata.image300} style={{width: 90, height: 90, borderRadius:3 }}></img>

    <c.View style={{flexDirection: 'column', justifyContent: 'center', paddingLeft: 15 }}>
        <texts.Header1>{metadata.name}</texts.Header1>
        <texts.Inactive>{list.length + ' episodes'}</texts.Inactive>
    </c.View>
    </c.View>
    )}
    <c.View style={{flexDirection: 'column', overflow: 'auto', flexGrow: 1}}>

    {list.map(item => {
        return renderItem(item)
    })}

    </c.View>

    {playerStatus === 'loading' && (

<c.PlayerBox justifyCenter>
<texts.Header2 inactive>Connecting to Spotify...</texts.Header2>
</c.PlayerBox>

)}

{playerStatus === 'no-active-client' && (
    <c.PlayerBox error>
    <img src={error} />
    <texts.Header2>No active Spotify player found</texts.Header2>
    <div style={{alignSelf:'flex-start', padding: 8}} onClick={() => setPlayerStatus('not-active')}>
    <img src={close}  />
    </div>
    </c.PlayerBox>
)}

{playerStatus === 'started' && (
    <c.PlayerBox active>
    <img src={'https://via.placeholder.com/51'} style={{width: 51, height: 51, borderRadius:3 }}></img>
    <texts.Header2 style={{flexShrink: 1}} marginL={10}>{playable?.name}</texts.Header2>
    <div onClick={() => stopPlayback()}>
    <img src={stop} />
    </div>
    </c.PlayerBox>
)}
    </c.BaseView>
  }
  